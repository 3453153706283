import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/edit-multiple-dialog";
import { MachineStatus, NotificationType } from "../../generated/client";
import strings from "../../localization/strings";
import { MachineStatusUtils } from "../../utils/machine-status-utils";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  open: boolean;
  machineStatuses: MachineStatus[];
  onCancel: () => void;
  onSave: (editedStatuses: MachineStatus[]) => void;
}

/**
 * Edit multiple dialog component
 *
 * @param props component props
 */
const EditMultipleDialog: React.FC<Props> = ({
  classes,
  open,
  machineStatuses,
  onCancel,
  onSave
}) => {

  const [ editedStatuses, setEditedStatuses ] = React.useState<MachineStatus[]>([]);
  const [ referenceStatus, setReferenceStatus ] = React.useState<MachineStatus>();

  React.useEffect(() => {
    setEditedStatuses(machineStatuses);
    setReferenceStatus(machineStatuses.length ? machineStatuses[0] : undefined);
  }, [ machineStatuses ]);

  /**
   * Event handler for on change
   *
   * @param name key of machine status
   */
  const onChange = (name: keyof MachineStatus) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!referenceStatus) {
      return;
    }

    const updatedStatus: MachineStatus = {
      ...referenceStatus,
      [name]: event.target.value
    };

    if (name === "notificationType") {
      updatedStatus.notificationTarget = undefined;
    }

    setReferenceStatus(updatedStatus);

    setEditedStatuses(editedStatuses.map(status => ({ ...updatedStatus, id: status.id })));
  }

  /**
   * Component render
   */
  return (
    <Dialog
      open={ open }
      onClose={ onCancel }
      className={ classes.dialog }
    >
      <DialogTitle>
        { strings.editMultiple }
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          size="medium"
          variant="outlined"
          type="number"
          className={ classes.textField }
          label={ `${strings.notificationDelay} (${strings.hours})` }
          value={ referenceStatus?.notificationDelay || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationDelay") }
        />
        <TextField
          select
          fullWidth
          size="medium"
          variant="outlined"
          className={ classes.textField }
          label={ strings.notificationType }
          value={ referenceStatus?.notificationType || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationType") }
        >
          { Object.values(NotificationType).map((option, index) =>
              <MenuItem key={ index } value={ option }>
                { MachineStatusUtils.displayNotificationType(option) }
              </MenuItem>
            )
          }
        </TextField>
        <TextField
          fullWidth
          size="medium"
          variant="outlined"
          className={ classes.textField }
          label={ MachineStatusUtils.displayNotificationTargetTitle(referenceStatus?.notificationType) }
          value={ referenceStatus?.notificationTarget || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationTarget") }
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={ onCancel }
          disableElevation
        >
          { strings.cancel }
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={ () => onSave(editedStatuses) }
          disableElevation
        >
          { strings.save }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(EditMultipleDialog);