import * as React from "react";

import { ReduxActions, ReduxState } from "../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { setMachineStatuses, updateMachineStatus } from "../../actions/machine-statuses";

import { CircularProgress, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/screens/main-screen";
import { History } from "history";
import AppLayout from "../layouts/app-layout";
import { AccessToken, MonitoringStatus } from "../../types";
import { KeycloakInstance } from "keycloak-js";
import { MachineStatus } from "../../generated/client";
import strings from "../../localization/strings";
import SubHeader from "../generic/sub-header";
import WithDebounce from "../generic/with-debounce";
import MachineStatusListItem from "../generic/machine-status-list-item";
import Api from "../../api/api";
import { MachineStatusUtils } from "../../utils/machine-status-utils";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import classnames from "classnames";
import SelectionToolbar from "../generic/selection-toolbar";
import EditMultipleDialog from "../generic/edit-multiple-dialog";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  accessToken?: AccessToken;
  keycloak?: KeycloakInstance;
  logout: typeof logout;
  machineStatuses?: MachineStatus[];
  updateMachineStatus: typeof updateMachineStatus;
  setMachineStatuses: typeof setMachineStatuses;
}

/**
 * Interface describing component state
 */
interface State {
  error: boolean;
  snackMessage?: string;
  searchValue?: string;
  selectedFilter?: MonitoringStatus;
  editedMachineStatusId?: string;
  selectedStatuses: MachineStatus[];
  editMultipleDialogOpen: boolean;
}

/**
 * Component for main screen
 */
class MainScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props 
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      selectedStatuses: [],
      editMultipleDialogOpen: false
    };
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component properties
   * @param prevState previous component state
   */
  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.searchValue !== this.state.searchValue) {
      this.setState({ selectedStatuses: [] });
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, machineStatuses } = this.props;
    const { selectedStatuses, editMultipleDialogOpen } = this.state;

    const filteredStatuses = machineStatuses?.filter(this.filterStatus);

    return (
      <AppLayout>
        <SubHeader>
          { this.renderSearchBar() }
          { this.renderFilterButtons() }
        </SubHeader>
        <SelectionToolbar
          statusesCount={ filteredStatuses?.length || 0 }
          selectedStatusesCount={ selectedStatuses.length }
          onSelectAll={ () => this.setState({ selectedStatuses: filteredStatuses || [] }) }
          onDeselectAll={ () => this.setState({ selectedStatuses: [] }) }
          onEditSelected={ this.onEditSelected }
        />
        <div className={ classes.scrollContainer }>
          { this.renderContent(filteredStatuses) }
        </div>
        <EditMultipleDialog
          open={ editMultipleDialogOpen }
          machineStatuses={ selectedStatuses }
          onCancel={ this.onCancelEditSelected }
          onSave={ this.updateMultiple }
        />
      </AppLayout>
    );
  }

  /**
   * Renders search bar
   */
  private renderSearchBar = () => {
    const { classes } = this.props;

    return (
      <WithDebounce
        label={ strings.search }
        onChange={ this.onSearch }
        debounceTimeout={ 250 }
        className={ classes.searchBar }
        component={ props =>
          <TextField
            { ...props }
            size="medium"
            variant="outlined"
            InputLabelProps={{ variant: "outlined" }}
          />
        }
      />
    );
  }

  /**
   * Renders filter buttons
   */
  private renderFilterButtons = () => {
    const { classes, machineStatuses } = this.props;
    const { selectedFilter } = this.state;

    if (!machineStatuses) {
      return null;
    }

    const onlineCount = MachineStatusUtils.getCountByMonitoringStatus(machineStatuses, MonitoringStatus.ONLINE);
    const missingSettingsCount = MachineStatusUtils.getCountByMonitoringStatus(machineStatuses, MonitoringStatus.MISSING_SETTINGS);
    const offlineCount = MachineStatusUtils.getCountByMonitoringStatus(machineStatuses, MonitoringStatus.OFFLINE);
    const disabledCount = MachineStatusUtils.getCountByMonitoringStatus(machineStatuses, MonitoringStatus.DISABLED);

    return (
      <ToggleButtonGroup
        value={ selectedFilter }
        exclusive
        onChange={ this.onToggleFilter }
        className={ classes.filterContainer }
      >
        {
          this.renderFilterButton(
            `${onlineCount} ${strings.online}`,
            MonitoringStatus.ONLINE,
            classes.online
          )
        }
        {
          this.renderFilterButton(
            `${missingSettingsCount} ${strings.missingSettings}`,
            MonitoringStatus.MISSING_SETTINGS,
            classes.missingSettings
          )
        }
        {
          this.renderFilterButton(
            `${offlineCount} ${strings.offline}`,
            MonitoringStatus.OFFLINE,
            classes.offline
          )
        }
        {
          this.renderFilterButton(
            `${disabledCount} ${strings.disabled}`,
            MonitoringStatus.DISABLED,
            classes.disabled
          )
        }
      </ToggleButtonGroup>
    );
  }

  /**
   * Renders filter button
   *
   * @param title button title
   * @param value monitoring status value
   * @param colorClassName indicator color class name
   */
  private renderFilterButton = (title: string, value: MonitoringStatus, colorClassName: string) => {
    const { classes } = this.props;

    return (
      <ToggleButton
        className={ classes.filterButton }
        value={ value }
      >
        <div className={ classnames(classes.indicator, colorClassName) }/>
        <Typography
          variant="caption"
          className={ classes.filterButtonText }
        >
          { title }
        </Typography>
      </ToggleButton>
    );
  }

  /**
   * Renders content
   *
   * @param filteredStatuses filtered machine statuses
   */
  private renderContent = (filteredStatuses?: MachineStatus[]) => {
    const { classes } = this.props;
    const { editedMachineStatusId, selectedStatuses } = this.state;

    if (!filteredStatuses) {
      return (
        <div className={ classes.loaderContainer }>
          <CircularProgress size={ 80 } />
        </div>
      );
    }

    return filteredStatuses.map(machineStatus =>
      <MachineStatusListItem
        key={ machineStatus.id }
        machineStatus={ machineStatus }
        selected={ selectedStatuses.some(status => status.id === machineStatus.id) }
        editedMachineStatusId={ editedMachineStatusId }
        onToggleSelected={ this.onToggleSelected }
        editDisabled={ !!selectedStatuses.length }
        onStartEdit={ this.onToggleEdit }
        onStopEdit={ this.onToggleEdit }
        onUpdate={ this.updateMachine }
        onDelete={ this.deleteMachineStatus }
      />
    );
  }

  /**
   * Filters status based on possible search value and selected filter
   *
   * @param machineStatus machine status
   */
  private filterStatus = (machineStatus: MachineStatus) => {
    const { searchValue, selectedFilter } = this.state;

    if (
      searchValue &&
      !machineStatus.machineId.includes(searchValue) &&
      !machineStatus.companyNumber?.includes(searchValue)
    ) {
      return false;
    }

    if (selectedFilter && MachineStatusUtils.getMonitoringStatus(machineStatus) !== selectedFilter) {
      return false;
    }

    return true;
  }

  /**
   * Updates machine status
   *
   * @param machineStatusToUpdate machine status to update
   */
  private updateMachine = async (machineStatusToUpdate: MachineStatus) => {
    const { accessToken, updateMachineStatus } = this.props;

    if (!accessToken) {
      return;
    }

    try {
      const machineStatusApi = Api.getMachineStatusApi(accessToken);
      const updatedMachineStatus = await machineStatusApi.updateMachineStatus({
        machineStatusId: machineStatusToUpdate.id!,
        machineStatus: machineStatusToUpdate
      });

      updateMachineStatus(updatedMachineStatus);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Updates multiple machine statuses
   *
   * @param statusesToUpdate statuses to update
   */
  private updateMultiple = async (statusesToUpdate: MachineStatus[]) => {
    const { accessToken, updateMachineStatus } = this.props;

    if (!accessToken) {
      return;
    }

    this.setState({ editMultipleDialogOpen: false });

    try {
      const machineStatusesApi = Api.getMachineStatusApi(accessToken);
      const updatedStatuses = await Promise.all(
        statusesToUpdate.map(machineStatus =>
          machineStatusesApi.updateMachineStatus({ machineStatusId: machineStatus.id!, machineStatus })
        )
      );

      updatedStatuses.forEach(updateMachineStatus);
  
      this.setState({
        selectedStatuses: []
      });
    } catch (error) {
      console.error("Error updating multiple statuses: ", error);
    }
  }

  /**
   * Deletes machine status
   *
   * @param machineStatus machine status to delete
   */
  private deleteMachineStatus = async (machineStatus: MachineStatus) => {
    const { accessToken, machineStatuses } = this.props;

    if (!accessToken || !machineStatus.id) {
      return;
    }

    const machineStatusApi = Api.getMachineStatusApi(accessToken);
    await machineStatusApi.deleteMachineStatus({ machineStatusId: machineStatus.id });

    this.props.setMachineStatuses(
      (machineStatuses || []).filter(status => status.id === machineStatus.id)
    );
  }

  /**
   * Event handler for search
   *
   * @param event React change event
   */
  private onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchValue: event.target.value,
      selectedStatuses: []
    });
  }

  /**
   * Event handler for toggle edit
   *
   * @param editedMachineStatus ID of edited machine status
   */
  private onToggleEdit = (editedMachineStatusId?: string) => {
    this.setState({ editedMachineStatusId });
  }

  /**
   * Event handler for toggle machine status selected
   *
   * @param machineStatus machine status to toggle select to
   */
  private onToggleSelected = (machineStatus: MachineStatus) => {
    const { selectedStatuses } = this.state;
    if (selectedStatuses.some(status => status.id === machineStatus.id)) {
      this.setState({
        selectedStatuses: selectedStatuses.filter(status => status.id !== machineStatus.id)
      })
    } else {
      this.setState({
        selectedStatuses: [ ...selectedStatuses, machineStatus ]
      })
    }
  }

  /**
   * Event handler for edit selected
   */
  private onEditSelected = () => {
    this.setState({ editMultipleDialogOpen: true });
  }

  /**
   * Event handler for cancel edit selected
   */
  private onCancelEditSelected = () => {
    this.setState({ editMultipleDialogOpen: false });
  }

  /**
   * Event handler for toggle filter
   *
   * @param event React mouse event
   * @param value filter value
   */
  private onToggleFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string | null) => {
    const selectedFilter = value as MonitoringStatus | null;

    this.setState({
      selectedFilter: selectedFilter ?? undefined
    });
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak,
  machineStatuses: state.machineStatuses.machineStatuses
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  logout: () => dispatch(logout()),
  updateMachineStatus: (machineStatus: MachineStatus) => dispatch(updateMachineStatus(machineStatus)),
  setMachineStatuses: (machineStatuses: MachineStatus[]) => dispatch(setMachineStatuses(machineStatuses))
});

const Styled = withStyles(styles)(MainScreen);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;