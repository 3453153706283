/* tslint:disable */
/* eslint-disable */
/**
 * Pickway API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TagInLine,
    TagInLineFromJSON,
    TagInLineToJSON,
} from '../models';

export interface FindTagsInLineRequest {
    tagId: string;
}

export interface ListTagsInLineRequest {
    automaattiId?: string;
    tagTilaId?: number;
    firmaNumero?: string;
    type?: string;
    limit?: number;
    firstResult?: number;
}

/**
 * 
 */
export class TagsInLineApi extends runtime.BaseAPI {

    /**
     * Finds tags matching specific tagid.
     * Finds Tags In Line.
     */
    async findTagsInLineRaw(requestParameters: FindTagsInLineRequest): Promise<runtime.ApiResponse<TagInLine>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling findTagsInLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tagsInLine/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagInLineFromJSON(jsonValue));
    }

    /**
     * Finds tags matching specific tagid.
     * Finds Tags In Line.
     */
    async findTagsInLine(requestParameters: FindTagsInLineRequest): Promise<TagInLine> {
        const response = await this.findTagsInLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists Tags In line based firm number attached to the access token.
     * Lists Tags In Line.
     */
    async listTagsInLineRaw(requestParameters: ListTagsInLineRequest): Promise<runtime.ApiResponse<Array<TagInLine>>> {
        const queryParameters: any = {};

        if (requestParameters.automaattiId !== undefined) {
            queryParameters['automaattiId'] = requestParameters.automaattiId;
        }

        if (requestParameters.tagTilaId !== undefined) {
            queryParameters['tagTilaId'] = requestParameters.tagTilaId;
        }

        if (requestParameters.firmaNumero !== undefined) {
            queryParameters['firmaNumero'] = requestParameters.firmaNumero;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tagsInLine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagInLineFromJSON));
    }

    /**
     * Lists Tags In line based firm number attached to the access token.
     * Lists Tags In Line.
     */
    async listTagsInLine(requestParameters: ListTagsInLineRequest): Promise<Array<TagInLine>> {
        const response = await this.listTagsInLineRaw(requestParameters);
        return await response.value();
    }

}
