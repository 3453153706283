import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import en from "./en.json";
import fi from "./fi.json";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {
  appTitle: string;
  logout: string;
  machineId: string;
  companyNumber: string;
  lastUpdate: string;
  hoursAgo: string;
  search: string;
  edit: string;
  save: string;
  cancel: string;
  activate: string;
  confirmActivate: string;
  deactivate: string;
  confirmDeactivate: string;
  notificationDelay: string;
  hours: string;
  notDefined: string;
  notificationType: string;
  emailMessage: string;
  email: string;
  online: string;
  missingSettings: string;
  offline: string;
  disabled: string;
  selectAll: string;
  deselectAll: string;
  selectedCount: string;
  editMultiple: string;
  delete: string;
  confirmDelete: string;
}

const strings: IStrings = new LocalizedStrings({ en, fi });

export default strings;
