import * as ActionTypes from '../constants/actionTypes';
import { MachineStatus } from '../generated/client';

/**
 * Interface for set machine statuses action type
 */
export interface SetMachineStatusesAction {
  type: ActionTypes.SET_MACHINE_STATUSES;
  machineStatuses: MachineStatus[];
}

/**
 * Interface for update machine status action type
 */
export interface UpdateMachineStatusAction {
  type: ActionTypes.UPDATE_MACHINE_STATUS;
  machineStatus: MachineStatus;
}

/**
 * Store method for set machine statuses
 *
 * @param machineStatuses list of machine statuses
 */
export const setMachineStatuses = (machineStatuses: MachineStatus[]): SetMachineStatusesAction => ({
  type: ActionTypes.SET_MACHINE_STATUSES,
  machineStatuses
});

/**
 * Store method for update machine status
 *
 * @param machineStatus machine status to update
 */
export const updateMachineStatus = (machineStatus: MachineStatus): UpdateMachineStatusAction => ({
  type: ActionTypes.UPDATE_MACHINE_STATUS,
  machineStatus
});

export type MachineStatusesAction = SetMachineStatusesAction | UpdateMachineStatusAction;