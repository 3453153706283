import * as React from 'react';
import { Provider } from "react-redux";
import { createStore } from 'redux';
import { ReduxActions, ReduxState, rootReducer } from "../store";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { responsiveFontSizes, ThemeProvider, CssBaseline, Theme } from '@material-ui/core';
import defaultTheme from "../theme/theme";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import strings from "../localization/strings";
import AccessTokenRefresh from "./containers/access-token-refresh";
import MainScreen from './screens/main-screen';
import StoreInitializer from './containers/store-initializer';

/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer);

/**
 * Material UI automated responsive font sizes
 */
const theme = responsiveFontSizes(defaultTheme);

/**
 * Interface describing component properties
 */
interface Props { }

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  theme: Theme;
}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      theme: defaultTheme
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    moment.locale(strings.getLanguage());

    const themePath = "/theme";
    await import(`../dynamic-content${themePath}`)
      .then(themeModule => this.setState({ theme: themeModule.default }))
      .catch(e => {
        console.log("Custom theme not found, using default theme", e);
      });

    this.setState({ loading: false });
  }

  /**
   * Component render
   */
  public render = () => {
    if (this.state.loading) {
      return null;
    }

    return (
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <Provider store={ store }>
          <AccessTokenRefresh>
            <StoreInitializer>
              <BrowserRouter>
                <Switch>
                  <Route
                    key="main"
                    path="/"
                    render={({ history }) => (
                      <MainScreen history={ history }/>
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </StoreInitializer>
          </AccessTokenRefresh>
        </Provider>
      </ThemeProvider>
    );
  }

}

export default App;