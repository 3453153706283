/**
 * Login action
 */
export const LOGIN = "LOGIN";
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally defined type and constant with the same name
export type LOGIN = typeof LOGIN;

/**
 * Logout action
 */
export const LOGOUT = "LOGOUT";
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally defined type and constant with the same name
export type LOGOUT = typeof LOGOUT;

/**
 * Set locale action
 */
export const SET_LOCALE = "SET_LOCALE";
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally defined type and constant with the same name
export type SET_LOCALE = typeof SET_LOCALE;

/**
 * Set machine statuses action
 */
export const SET_MACHINE_STATUSES = "SET_MACHINE_STATUSES";
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally defined type and constant with the same name
export type SET_MACHINE_STATUSES = typeof SET_MACHINE_STATUSES;

/**
 * Update machine status action
 */
export const UPDATE_MACHINE_STATUS = "UPDATE_MACHINE_STATUS";
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally defined type and constant with the same name
export type UPDATE_MACHINE_STATUS = typeof UPDATE_MACHINE_STATUS;