import * as React from "react";
import { AppBar, Menu, MenuItem, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/top-bar";
import strings from "../../localization/strings";
import { KeycloakInstance } from "keycloak-js";
import { ReduxActions, ReduxState } from "../../store";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  keycloak?: KeycloakInstance;
}

/**
 * Top bar component
 * 
 * @param props component props
 */
const TopBar: React.FC<Props> = ({ classes, keycloak }) => {

  const [ userProfile, setUserProfile ] = React.useState<Keycloak.KeycloakProfile>();
  const [ anchorElement, setAnchorElement ] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    keycloak?.loadUserProfile().then(profile => {
      setUserProfile(profile);
    });
  }, [ keycloak ]);


  const firstName = userProfile?.firstName ?? "";
  const lastName = userProfile?.lastName ?? "";

  /**
   * Handles open dropdown menu
   * 
   * @param event React mouse event
   */
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  /**
   * Handles close dropdown menu
   */
  const handleClose = () => {
    setAnchorElement(null);
  };

  /**
   * Component render
   */
  return (
    <div className={ classes.root }>
      <AppBar position="relative">
        <Toolbar className={ classes.toolBar }>
          <Typography
            variant="h5"
            className={ classes.appTitle }
          >
            { strings.appTitle }
          </Typography>
          <div
            className={ classes.accountButton }
            onClick={ handleOpen }
          >
            { getFirstLetters(firstName, lastName) }
          </div>
          <Menu
            anchorEl={ anchorElement }
            keepMounted
            open={ !!anchorElement }
            onClose={ handleClose }
          >
            <MenuItem
              onClick={ () => {
                handleClose();
                keycloak?.logout();
              }}
            >
              { strings.logout }
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}

/**
 * Gets first letter from given string and makes it uppercase
 * 
 * @param strings strings to get first letters from
 * @returns first letters as a string
 */
const getFirstLetters = (string: string, ...additionalStrings: string[]) => {
  return [string, ...additionalStrings]
    .map(string => string.substring(0, 1))
    .join("");
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  keycloak: state.auth.keycloak
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: React.Dispatch<ReduxActions>) => ({
  onLogout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopBar));
