import { Configuration, MachineStatusApi } from "../generated/client";
import { AccessToken } from "../types";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized machine status api
   *
   * @param token access token
   */
  public static getMachineStatusApi(accessToken: AccessToken) {
    return new MachineStatusApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets api configuration
   *
   * @param token access token
   */
  private static getConfiguration(accessToken: AccessToken) {
    return new Configuration({
      basePath: process.env.REACT_APP_API_BASE_PATH,
      headers: {
        "Authorization": `Bearer ${ accessToken.token }`
      }
    });
  }

}
