import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxActions, ReduxState } from "../../store";
import { AccessToken } from "../../types";
import { KeycloakInstance } from "keycloak-js";
import Api from "../../api/api";
import { setMachineStatuses } from "../../actions/machine-statuses";
import { MachineStatus } from "../../generated/client";

/**
 * Component props
 */
interface Props {
  keycloak?: KeycloakInstance;
  accessToken?: AccessToken;
  children?: React.ReactNode;
  setMachineStatuses: typeof setMachineStatuses;
};

/**
 * Component state
 */
interface State {
  error?: Error;
}

/**
 * Component for fetching initial data
 */
class StoreInitializer extends React.Component<Props, State> {

  /** Timer for fetching machine status */
  private fetchStatusTimer?: NodeJS.Timer;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    await this.fetchData();
    this.startFetchTimer();
  }

  /**
   * Component render method
   */
  public render = () => this.props.children;

  /**
   * Fetch application data
   */
  private fetchData = async () => {
    try {
      const { accessToken } = this.props;
      if (!accessToken) {
        return;
      }

      const machineStatusApi = Api.getMachineStatusApi(accessToken);
      const machineStatuses = await machineStatusApi.listMachineStatuses({ });
      this.props.setMachineStatuses(machineStatuses);
    } catch (error) {
      this.setState({ error });
    }
  }

  /**
   * Starts fetch timer
   */
  private startFetchTimer = () => {
    this.fetchStatusTimer && this.stopFetchTimer();
    this.fetchStatusTimer = setInterval(() => this.fetchData(), 5000);
  }

  /**
   * Stops fetch timer
   */
  private stopFetchTimer = () => {
    this.fetchStatusTimer && clearInterval(this.fetchStatusTimer);
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setMachineStatuses: (machineStatuses: MachineStatus[]) => dispatch(setMachineStatuses(machineStatuses))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInitializer);
