/* tslint:disable */
/* eslint-disable */
/**
 * Pickway API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StorageTransaction,
    StorageTransactionFromJSON,
    StorageTransactionToJSON,
} from '../models';

export interface ListStorageTransactionsRequest {
    automaattiId?: string;
    startDate?: Date;
    endDate?: Date;
    type?: ListStorageTransactionsTypeEnum;
    limit?: number;
    firstResult?: number;
}

/**
 * 
 */
export class StorageTransactionsApi extends runtime.BaseAPI {

    /**
     * Lists storage transactions based on firmnumber attached to the access token.
     * Lists storage transactions.
     */
    async listStorageTransactionsRaw(requestParameters: ListStorageTransactionsRequest): Promise<runtime.ApiResponse<Array<StorageTransaction>>> {
        const queryParameters: any = {};

        if (requestParameters.automaattiId !== undefined) {
            queryParameters['automaattiId'] = requestParameters.automaattiId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/storageTransactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StorageTransactionFromJSON));
    }

    /**
     * Lists storage transactions based on firmnumber attached to the access token.
     * Lists storage transactions.
     */
    async listStorageTransactions(requestParameters: ListStorageTransactionsRequest): Promise<Array<StorageTransaction>> {
        const response = await this.listStorageTransactionsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListStorageTransactionsTypeEnum {
    Otto = 'Otto',
    Lainaus = 'Lainaus',
    Palautus = 'Palautus',
    Tytto = 'Täytto'
}
