/* tslint:disable */
/* eslint-disable */
/**
 * Pickway API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MachineStatus,
    MachineStatusFromJSON,
    MachineStatusToJSON,
} from '../models';

export interface DeleteMachineStatusRequest {
    machineStatusId: string;
}

export interface ListMachineStatusesRequest {
    machineId?: string;
    limit?: number;
    firstResult?: number;
}

export interface PostMachineStatusRequest {
    machineStatus: MachineStatus;
}

export interface UpdateMachineStatusRequest {
    machineStatus: MachineStatus;
    machineStatusId: string;
}

/**
 * 
 */
export class MachineStatusApi extends runtime.BaseAPI {

    /**
     * Deletes machine status.
     * Delete Machine status.
     */
    async deleteMachineStatusRaw(requestParameters: DeleteMachineStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineStatusId === null || requestParameters.machineStatusId === undefined) {
            throw new runtime.RequiredError('machineStatusId','Required parameter requestParameters.machineStatusId was null or undefined when calling deleteMachineStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machineStatus/{machineStatusId}`.replace(`{${"machineStatusId"}}`, encodeURIComponent(String(requestParameters.machineStatusId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes machine status.
     * Delete Machine status.
     */
    async deleteMachineStatus(requestParameters: DeleteMachineStatusRequest): Promise<void> {
        await this.deleteMachineStatusRaw(requestParameters);
    }

    /**
     * List machine statuses.
     * Lists Machine statuses.
     */
    async listMachineStatusesRaw(requestParameters: ListMachineStatusesRequest): Promise<runtime.ApiResponse<Array<MachineStatus>>> {
        const queryParameters: any = {};

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machineStatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineStatusFromJSON));
    }

    /**
     * List machine statuses.
     * Lists Machine statuses.
     */
    async listMachineStatuses(requestParameters: ListMachineStatusesRequest): Promise<Array<MachineStatus>> {
        const response = await this.listMachineStatusesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Post machine status.
     * Post Machine status.
     */
    async postMachineStatusRaw(requestParameters: PostMachineStatusRequest): Promise<runtime.ApiResponse<MachineStatus>> {
        if (requestParameters.machineStatus === null || requestParameters.machineStatus === undefined) {
            throw new runtime.RequiredError('machineStatus','Required parameter requestParameters.machineStatus was null or undefined when calling postMachineStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machineStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineStatusToJSON(requestParameters.machineStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineStatusFromJSON(jsonValue));
    }

    /**
     * Post machine status.
     * Post Machine status.
     */
    async postMachineStatus(requestParameters: PostMachineStatusRequest): Promise<MachineStatus> {
        const response = await this.postMachineStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update machine status.
     * Update Machine status.
     */
    async updateMachineStatusRaw(requestParameters: UpdateMachineStatusRequest): Promise<runtime.ApiResponse<MachineStatus>> {
        if (requestParameters.machineStatus === null || requestParameters.machineStatus === undefined) {
            throw new runtime.RequiredError('machineStatus','Required parameter requestParameters.machineStatus was null or undefined when calling updateMachineStatus.');
        }

        if (requestParameters.machineStatusId === null || requestParameters.machineStatusId === undefined) {
            throw new runtime.RequiredError('machineStatusId','Required parameter requestParameters.machineStatusId was null or undefined when calling updateMachineStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machineStatus/{machineStatusId}`.replace(`{${"machineStatusId"}}`, encodeURIComponent(String(requestParameters.machineStatusId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineStatusToJSON(requestParameters.machineStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineStatusFromJSON(jsonValue));
    }

    /**
     * Update machine status.
     * Update Machine status.
     */
    async updateMachineStatus(requestParameters: UpdateMachineStatusRequest): Promise<MachineStatus> {
        const response = await this.updateMachineStatusRaw(requestParameters);
        return await response.value();
    }

}
