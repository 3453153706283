import { CSSProperties } from "react";

/**
 * Interface describing an access token
 */
export interface AccessToken {
  token: string;
  userId: string;
};

/**
 * Interface describing custom CSS styles
 */
export interface CustomStyles {
  [className: string]: CSSProperties;
}

/**
 * Enum type for monitoring status of machine status
 */
export enum MonitoringStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  MISSING_SETTINGS = "missing-settings",
  DISABLED = "disabled"
}