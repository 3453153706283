import { createStyles } from "@material-ui/core";

export const styles = createStyles({

  root: {
    width: "100%",
    height: 0,
    display: "flex",
    alignItems: "center",
    transition: "all 0.25s ease-in-out",
    overflow: "hidden",
    marginLeft: 40,
    paddingLeft: 20
  },

  open: {
    height: 60,
    marginTop: 10,
    marginBottom: 0
  },

  selectedText: {
    marginLeft: 10,
    marginRight: 30
  }

});