/* tslint:disable */
/* eslint-disable */
/**
 * Pickway API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationType,
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MachineStatus
 */
export interface MachineStatus {
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    machineId: string;
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    companyNumber?: string | null;
    /**
     * Time of last successful status message from the machine
     * @type {Date}
     * @memberof MachineStatus
     */
    lastUpdate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MachineStatus
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MachineStatus
     */
    notificationDelay?: number | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof MachineStatus
     */
    notificationType?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof MachineStatus
     */
    notificationTarget?: string;
}

export function MachineStatusFromJSON(json: any): MachineStatus {
    return MachineStatusFromJSONTyped(json, false);
}

export function MachineStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'machineId': json['machineId'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'lastUpdate': !exists(json, 'lastUpdate') ? undefined : (new Date(json['lastUpdate'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'notificationDelay': !exists(json, 'notificationDelay') ? undefined : json['notificationDelay'],
        'notificationType': !exists(json, 'notificationType') ? undefined : NotificationTypeFromJSON(json['notificationType']),
        'notificationTarget': !exists(json, 'notificationTarget') ? undefined : json['notificationTarget'],
    };
}

export function MachineStatusToJSON(value?: MachineStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'machineId': value.machineId,
        'companyNumber': value.companyNumber,
        'lastUpdate': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString()),
        'active': value.active,
        'notificationDelay': value.notificationDelay,
        'notificationType': NotificationTypeToJSON(value.notificationType),
        'notificationTarget': value.notificationTarget,
    };
}


