import { combineReducers } from "redux";
import { authReducer } from "../reducers/auth";
import { localeReducer } from "../reducers/locale";
import { AuthAction } from "../actions/auth";
import { LocaleAction } from "../actions/locale";
import { machineStatusesReducer } from "../reducers/machine-statuses";
import { MachineStatusesAction } from "../actions/machine-statuses";

/**
 * Root reducer that wraps all Redux reducers
 */
export const rootReducer = combineReducers({
    auth: authReducer,
    locale: localeReducer,
    machineStatuses: machineStatusesReducer
});

/**
 * Type for Redux state
 */
export type ReduxState = ReturnType<typeof rootReducer>;

/**
 * Union type for Redux actions
 */
export type ReduxActions = AuthAction | LocaleAction | MachineStatusesAction;