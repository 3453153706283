import { MachineStatusesAction } from '../actions/machine-statuses';
import { SET_MACHINE_STATUSES, UPDATE_MACHINE_STATUS } from '../constants/actionTypes';
import { Reducer } from "redux";
import { MachineStatus } from '../generated/client';

/**
 * Machine statuses state
 */
interface MachineStatusesState {
  machineStatuses?: MachineStatus[];
}

/**
 * Initial machine statuses state
 */
const initialState: MachineStatusesState = { }

/**
 * Redux reducer for machine statuses
 *
 * @param state store state of machine statuses
 * @param action action of machine statuses
 */
export const machineStatusesReducer: Reducer<MachineStatusesState, MachineStatusesAction> = (state = initialState, action): MachineStatusesState => {
  switch (action.type) {
    case SET_MACHINE_STATUSES:
      return {
        ...state,
        machineStatuses: action.machineStatuses
      };
    case UPDATE_MACHINE_STATUS:
      return {
        ...state,
        machineStatuses: state.machineStatuses?.map(machineStatusInState =>
          machineStatusInState.id === action.machineStatus.id ?
            action.machineStatus :
            machineStatusInState
        )
      };
    default:
      return state;
  }
}