import moment from "moment";
import { MachineStatus, NotificationType } from "../generated/client";
import strings from "../localization/strings";
import { MonitoringStatus } from "../types";

/**
 * Utility class for machine statuses
 */
export class MachineStatusUtils {

  /**
   * Returns machine count with given monitoring status from given machine statuses list
   *
   * @param machineStatuses machine statuses list
   * @param status status to filter machines with
   */
  public static getCountByMonitoringStatus = (machineStatuses: MachineStatus[], status: MonitoringStatus) => {
    return machineStatuses
      .filter(machineStatus => MachineStatusUtils.getMonitoringStatus(machineStatus) === status)
      .length;
  }

  /**
   * Returns monitoring status of the machine
   *
   * @param machineStatus machine status
   * @returns monitoring status of the machine
   */
  public static getMonitoringStatus = (machineStatus: MachineStatus): MonitoringStatus => {
    const {
      active,
      lastUpdate,
      notificationDelay,
      notificationType,
      notificationTarget
    } = machineStatus;

    if (!active) {
      return MonitoringStatus.DISABLED;
    }

    if (!notificationDelay || !notificationType || !notificationTarget) {
      return MonitoringStatus.MISSING_SETTINGS;
    }

    if (moment().diff(lastUpdate, "hours") > notificationDelay) {
      return MonitoringStatus.OFFLINE;
    }

    return MonitoringStatus.ONLINE;
  }

  /**
   * Displays notification type
   *
   * @param notificationType notification type
   */
  public static displayNotificationType = (notificationType?: NotificationType) => {
    switch (notificationType) {
      case NotificationType.Email:
      default:
        return strings.emailMessage;
    }
  }

  /**
   * Renders notification target column
   *
   * @param notificationType notification type
   */
  public static displayNotificationTargetTitle = (notificationType?: NotificationType) => {
    switch (notificationType) {
      case NotificationType.Email:
      default:
        return strings.email;
    }
  }

}