import * as React from "react";
import { AppBar, Toolbar, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/sub-header";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {}

/**
 * Sub header component
 *
 * @param props component props
 */
const SubHeader: React.FC<Props> = ({ classes, children }) => {
  return (
    <AppBar
      position="relative"
      className={ classes.appBar }
    >
      <Toolbar className={ classes.toolBar }>
        { children }
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(styles)(SubHeader);