import { createStyles } from "@material-ui/core";

export const styles = createStyles({

  dialog: { },

  textField: {
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0
    }
  }

});