import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  machineStatusCard: {
    margin: 20,
    display: "flex",
    padding: "20px 30px",
    "& .MuiCardContent-root:last-child": {
      padding: 0
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: 162
    },
    [theme.breakpoints.down("md")]: {
      minHeight: 223
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 248
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 320
    },
  },

  cardContent: {
    flex: 1,
    display: "flex",
    padding: 0
  },

  textFieldContainer: {
    height: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center"
    }
  },

  contentColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 5
  },

  actionButtonColumn: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },

  value: {
    margin: 0
  },

  selectContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: 10
  },

  indicatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 30
  },

  onlineIndicator: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down("xs")]: {
      border: "none",
      borderRadius: 2,
      height: "100%",
      width: 3
    }
  },

  outlinedButton: {
    padding: "8px 16px",
    marginLeft: 20,
    fontWeight: "bold",
    letterSpacing: 0.1,
    "&:not(:last-child)": {
      marginBottom: 10
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      padding: "15px 10px"
    }
  },

  containedButton: {
    padding: "8px 16px",
    marginLeft: 20,
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.common.white,
    fontWeight: "bold",
    letterSpacing: 0.1,
    "&:not(:last-child)": {
      marginBottom: 10
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      padding: "15px 10px"
    }
  },

  textField: {
    width: "33%",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: 20,
      "&:last-child": {
        marginBottom: 0
      },
    }
  },

  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.black,
    "&:hover": {
      backgroundColor: theme.palette.warning.main
    }
  },

  danger: {
    backgroundColor: theme.palette.error.light,
    "&:hover": {
      backgroundColor: theme.palette.error.main
    }
  }

});
